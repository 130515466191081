import React, { useState, useEffect } from 'react';
import {
    Typography, Button, Box, TextField, Grid, Paper, TableContainer, Tab, Dialog,
    DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DataGrid } from '@mui/x-data-grid';
import api from '../axiosInstance';
import CheckoutForm from '../components/order/CheckoutForm';

const columns = [
    {
        field: 'logo',
        headerName: '',
        renderCell: (params) => (
            <img alt='logo' src={params.value} style={{
                maxHeight: '50px',
                maxWidth: '100%',
                borderRadius: 5,
                padding: '10px'

            }} />
        ),
        sortable: false,
        filterable: false,
        width: 60
    },
    {
        field: 'name',
        headerName: 'Company',
        width: 130
    },
    {
        field: 'final_reservation_amount',
        headerName: 'Reservation Amount',
        renderCell: (params) => <NumberFormatter number={params.value} />,
        width: 200,
    },
    {
        field: 'equivalent_number_of_shares',
        headerName: '# of shares',
        width: 180,
    },
    {
        field: 'implied_share_price',
        headerName: 'Share price',
        renderCell: (params) => <NumberFormatter number={params.value} />,
        width: 180,
    },


];


// Define the NumberFormatter component
const NumberFormatter = ({ number }) => {
    const formattedNumber = new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 0,
    }).format(number);

    return <span>{formattedNumber}</span>;
};

// Define the SearchBar component
const SearchBar = ({ searchQuery, setSearchQuery }) => {
    return (
        <Box display="flex" justifyContent="flex-end" mb={2} style={{ padding: '20px' }}>
            <TextField
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search by name..."
                size="small"
            />
        </Box>
    );
};

const Portfolio = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentTab, setCurrentTab] = useState('1');
    const [open, setOpen] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [dealId, setDealId] = useState(null);
    const [orderId, setOrderId] = useState(null);


    const [filteredOrders, setFilteredOrders] = useState({
        ongoing: [],
        reserved: [],
        closed: [],
        exited: [],
    });

    const unpaidColumns = [
        {
            field: 'logo',
            headerName: '',
            renderCell: (params) => (
                <img alt='logo' src={params.value} style={{
                    maxHeight: '35px',
                    maxWidth: '100%',
                    borderRadius: 5,
                    padding: '15px'

                }} />
            ),
            sortable: false,
            filterable: false,
            width: 60
        },
        {
            field: 'name',
            headerName: 'Company',
            width: 130
        },
        {
            field: 'final_reservation_amount',
            headerName: 'Reservation Amount',
            renderCell: (params) => <NumberFormatter number={params.value} />,
            width: 200,
        },
        {
            field: 'equivalent_number_of_shares',
            headerName: '# of shares',
            width: 180,
        },
        {
            field: 'implied_share_price',
            headerName: 'Share price',
            renderCell: (params) => <NumberFormatter number={params.value} />,
            width: 180,
        },
        {
            field: 'button',
            headerName: '',
            renderCell: (params) => <Button sx={{ 
                padding: '10px 20px',  // Adjust padding as needed
            }}
             variant="contained" color="secondary" onClick={() => handleClickOpen(params.row)}> Make Payment </Button>,
            width: 180,
        },



    ];
    const handleClickOpen = (rowData) => {

        const quantity = rowData.equivalent_number_of_shares !== undefined && rowData.equivalent_number_of_shares !== null 
        ? rowData.equivalent_number_of_shares 
        : 0;

    console.log('Quantity:', quantity); // Debugging the extracted quantity

        const o = {
            price: "price_1Ps3UQCFu7uROflV4KmutHzj",
            quantity:quantity

        }
        setOrderId(rowData.id)
        setOrderData(o)
        setDealId(rowData.deal)
        setOpen(true);  
    };


    const handleClose = () => {
        setOpen(false); 
        setDealId(null);
        setOrderData(null);
    };

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await api.get('api/orders/list/me/');
                const ordersData = response.data;
                const ongoingOrders = ordersData.filter(order => order.status === 0);
                const reservedOrders = ordersData.filter(order => order.status === 1);
                const closedOrders = ordersData.filter(order => order.status === 2);
                const exitedOrders = ordersData.filter(order => order.status === 3);

                console.log(ongoingOrders)
                setFilteredOrders({
                    ongoing: ongoingOrders,
                    reserved: reservedOrders,
                    closed: closedOrders,
                    exited: exitedOrders,
                });
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, []);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const rows = (orders) => orders.map(order => ({
        name: order.name,
        logo: order.logo,
        id: order.id,
        equivalent_number_of_shares: order.equivalent_number_of_shares,
        final_reservation_amount: order.final_reservation_amount,
        deal: order.deal,
        implied_share_price: order.implied_share_price,
        button: 'Please Pay'
    }));

    return (
        <div>
            <Grid container display="flex" spacing={2} justifyContent={'center'} padding="40px">
                <Grid item xs={12} lg={10} component={Paper} sx={{ width: '100%', typography: 'body1', padding: '60px', topMargin: '60px' }}>
                    <Typography variant="h3" gutterBottom>
                        Portfolio
                    </Typography>
                    <Box width="100%" sx={{ width: '100%', display: 'flex' }}>
                        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    </Box>
                    <TabContext value={currentTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="portfolio navigation" textColor="secondary" indicatorColor="secondary">
                                <Tab label="Ongoing" value="1" />
                                <Tab label="Reserved" value="2" />
                                <Tab label="Closed" value="3" />
                                <Tab label="Exited" value="4" />
                            </TabList>
                        </Box>

                        <TabPanel value="1">
                            <Typography variant="h4" gutterBottom>
                                Your ongoing deals
                            </Typography>
                            <TableContainer component={Paper} sx={{ width: '100%', maxHeight: 400 }}>
                                <DataGrid
                                    rows={rows(filteredOrders.ongoing)}
                                    columns={unpaidColumns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    rowHeight={70}  // Adjust this value to make rows taller
                                    disableColumnMenu
                                />
                            </TableContainer>
                        </TabPanel>

                        <TabPanel value="2">
                            <Typography variant="h4" gutterBottom>
                                Your reserved deals
                            </Typography>
                            <TableContainer component={Paper} sx={{ width: '100%', maxHeight: 400 }}>
                                <DataGrid
                                    rows={rows(filteredOrders.reserved)}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    disableColumnMenu
                                />
                            </TableContainer>
                        </TabPanel>

                        <TabPanel value="3">
                            <Typography variant="h4" gutterBottom>
                                Your closed deals
                            </Typography>
                            <TableContainer component={Paper} sx={{ width: '100%', maxHeight: 400 }}>
                                <DataGrid
                                    rows={rows(filteredOrders.closed)}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    disableColumnMenu
                                />
                            </TableContainer>
                        </TabPanel>

                        <TabPanel value="4">
                            <Typography variant="h4" gutterBottom>
                                Your exited deals
                            </Typography>
                            <TableContainer component={Paper} sx={{ width: '100%', maxHeight: 400 }}>
                                <DataGrid
                                    rows={rows(filteredOrders.exited)}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    disableColumnMenu
                                />
                            </TableContainer>
                        </TabPanel>
                    </TabContext>

                    <Button variant="contained" color="secondary" href="/investments"> Explore new offerings </Button>
                </Grid>
            </Grid>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Complete Payment</DialogTitle>
                <DialogContent>

                    {orderData && dealId && orderId && (
                        <CheckoutForm formData={orderData} dealId={dealId} orderId={orderId}>
                        </CheckoutForm>

                    )}

                </DialogContent>


                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default Portfolio;
