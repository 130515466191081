import React, { useState } from 'react';
import { Paper, Box, Tab, Grid, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import BasicProfile from '../components/profiles/BasicProfile'; 
import BankingProfiles from  '../components/profiles/BankingProfiles';
import InvestingProfile from  '../components/profiles/InvestingProfile';
import VerifyProfile from  '../components/profiles/VerifyProfile';

const Profile = () => {
    const [value, setValue] = useState("1"); 
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container display='flex' spacing={2} justifyContent={'center'} padding='40px'>
            <Grid item xs={12} lg={10} component={Paper} sx={{ width: '100%', typography: 'body1', padding: '60px' }}>
                <Typography variant="h3" gutterBottom>
                    Your Account
                </Typography>

                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="profile navigation" textColor="secondary" indicatorColor="secondary">
                            <Tab label="Basic Profile Information" value="1" />
                            <Tab label="Investing Suitability" value="2" />
                            <Tab label="Payment Profiles" value="3" />
                            <Tab label="Profile Verification" value="4" />
                        </TabList>
                    </Box>

                    <TabPanel value="1">
                        <BasicProfile />
                    </TabPanel>
                    <TabPanel value="2">
                        <InvestingProfile />
                    </TabPanel>
                    <TabPanel value="3">
                        <BankingProfiles />
                    </TabPanel>
                    <TabPanel value="4">
                        <VerifyProfile />
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    );
};

export default Profile;
