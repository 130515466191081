import axios from 'axios';

const baseURL = 'https://api.invest.lzh.ventures'

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, 
});

export default axiosInstance;



   