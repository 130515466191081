import React from 'react';
import { Button } from '@mui/material';

const DealStatusButton = ({ status, onClick }) => {
    const statusConfig = {
        INVEST_NOW: {
            text: 'Invest Now',
            color: 'secondary',
            variant: 'contained',
            action: () => onClick('invest_now'),
        },
        COMING_SOON: {
            text: 'Coming Soon',
            color: 'secondary',
            variant: 'text',
            action: () => alert('This is not yet available'),
        },
        WAITING_LIST: {
            text: 'Join Waitlist',
            color: 'secondary',
            variant: 'bright',
            action: () => onClick('waiting_list'),
        },
    };

    const { text, color, variant, action } = statusConfig[status] || {
        text: 'Unknown',
        color: 'color',
        backgroundColor: 'backgroundColor',
        variant: 'text',
        action: () => {},
    };

    return (
        <Button
            variant={variant}
            size="small"
            color={color}
            onClick={action}
        >
            {text}
        </Button>
    );
};

export default DealStatusButton;