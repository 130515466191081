import {
    LOAD_BANKING_PROFILES_SUCCESS,
    LOAD_BANKING_PROFILES_FAIL,
    UPDATE_BANKING_PROFILE_SUCCESS,
    UPDATE_BANKING_PROFILE_FAIL,
    CREATE_BANKING_PROFILE_SUCCESS,
    CREATE_BANKING_PROFILE_FAIL
} from '../actions/types';

const initialState = {
    profiles: [],  // Assuming there can be multiple banking profiles
    email: '',
    error: null
};

const banking = function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOAD_BANKING_PROFILES_SUCCESS:
            return {
                ...state,
                email: payload.email | state.email,
                profiles: Array.isArray(payload.profile) ? payload.profile : state.profiles // Ensure profiles is an array
            };

        case UPDATE_BANKING_PROFILE_SUCCESS:
            return {
                ...state,
                profiles: state.profiles.map(profile => 
                profile.id === payload.profile.id ? payload.profile : profile
                    )
                };

        case CREATE_BANKING_PROFILE_SUCCESS:
            return {
                ...state,
                profiles: [...state.profiles, payload.profile]  // Append the new profile
            };

        case LOAD_BANKING_PROFILES_FAIL:
        case UPDATE_BANKING_PROFILE_FAIL:
        case CREATE_BANKING_PROFILE_FAIL:
            return {
                ...state,
                error: payload // Save error message
            };

        default:
            return state;
    }
};

export default banking;
