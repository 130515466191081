
// Auth Dispatch States

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';

// Profile Dispatch States

export const LOAD_INVESTOR_PROFILE_SUCCESS = 'LOAD_INVESTOR_PROFILE_SUCCESS';
export const LOAD_INVESTOR_PROFILE_FAIL = 'LOAD_INVESTOR_PROFILE_FAIL';
export const UPDATE_INVESTOR_PROFILE_SUCCESS = 'UPDATE_INVESTOR_PROFILE_SUCCESS';
export const UPDATE_INVESTOR_PROFILE_FAIL = 'UPDATE_INVESTOR_PROFILE_FAIL';


// Banking States

export const LOAD_BANKING_PROFILES_SUCCESS = 'LOAD_BANKING_PROFILES_SUCCESS';
export const LOAD_BANKING_PROFILES_FAIL = 'LOAD_BANKING_PROFILES_FAIL';
export const UPDATE_BANKING_PROFILE_SUCCESS = 'UPDATE_BANKING_PROFILE_SUCCESS';
export const UPDATE_BANKING_PROFILE_FAIL = 'UPDATE_BANKING_PROFILE_FAIL';
export const CREATE_BANKING_PROFILE_SUCCESS = 'CREATE_BANKING_PROFILE_SUCCESS';
export const CREATE_BANKING_PROFILE_FAIL = 'CREATE_BANKING_PROFILE_FAIL';
