import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Typography, Box, Grid } from '@mui/material';
import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const BasicChart = function BasicArea() {
    const theme = useTheme();


const years = [
    new Date(2015, 0, 1),
    new Date(2017, 0, 1),
    new Date(2018, 0, 1),
    new Date(2020, 0, 1),
    new Date(2021, 0, 1),
  ];
    return (
        <Grid container spacing={2}
        style={{
            display:'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems:'center',
        }}>


        <Box item xs={12} lg={4} component={Paper} style = {{padding: '40px', margin: '40px'}}>
            <Typography variant='h5'> Share Price History </Typography>

            <LineChart
                xAxis={[{ 
                    data: years ,
                    scaleType: 'time',
                    valueFormatter: (date) => date.getFullYear().toString(),
              
                }]}
                series={[
                    {
                        data: [2, 5.5, 2, 8.5, 1.5 ],
                        area: true,
                        color: theme.palette.primary.light
                    },
                ]}
                width={600}
                height={300}
                grid={{ vertical: true, horizontal: true }}
            />
        
        </Box>
            
        <Box item xs={12} lg={4} component={Paper} style = {{ padding: '40px', margin: '40px' }}>
            <Typography variant='h5'> Valuation History </Typography>

            <LineChart
                xAxis={[{ 
                    data: years ,
                    scaleType: 'time',
                    valueFormatter: (date) => date.getFullYear().toString(),
                }]}
                series={[
                    {
                        data: [0, 3.8, 253.8, 753.8, 1653],
                        area: true,
                        color: theme.palette.secondary.main
                    },
                ]}
                width={600}
                height={300}
                grid={{ vertical: true, horizontal: true }}
                
            />
        
        </Box>
        <Box item xs={0} lg={4} >

        </Box>


        </Grid>
    );
}
export default BasicChart;
