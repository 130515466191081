import React, { useState, useEffect }  from 'react';
import {
  TextField, Grid, Box, Paper, Typography
} from '@mui/material';
import Loading from '../Loading';
import api from '../../axiosInstance';
import { useParams } from 'react-router-dom';


const ReservationDetails = ({ formData, setFormData, handleChange }) => {

  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { dealId } = useParams();
  const [deal, setDeal] = useState(null);
 

  useEffect(() => {
    const fetchDeal = async () => {
      try {
        const response = await api.get(`/api/products/deals/id/${dealId}/`);
        setDeal(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchDeal();
  }, [dealId]);

  useEffect(() => {
    if (deal) {
      const initialFormData = {
        reservation_amount: deal.minimum_investment_size,
        share_price: deal.implied_share_price,
        equivalent_number_of_shares: Math.round((0.95 * deal.minimum_investment_size) / deal.implied_share_price),
        final_reservation_amount: 0.95 * deal.minimum_investment_size,
      };
      setFormData(initialFormData);

      const fetchCompany = async () => {
        try {
          const response = await api.get(`/api/products/companies/${deal.company}/`);
          setCompany(response.data);
        } catch (error) {
          setError(error.message);
          console.log(error.message)
        }
      };

      fetchCompany();
    }
  }, [deal, setFormData]);
  
  const renderOrderForm = () => {
    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Box>Error: {error}</Box>;
    }

    return (
      <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item xs={12} lg={12}>
          <TextField
            required
            label="Number of Shares"
            name="equivalent_number_of_shares"
            value={formData.equivalent_number_of_shares}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            disabled
            required
            label="Share Price (excl. Sales Fee)"
            name="share_price"
            value={formData.share_price}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            disabled
            required
            label="Reserved Value of Stocks"
            name="final_reservation_amount"
            value={formData.final_reservation_amount}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            required
            disabled
            label="Final amount"
            name="reservation_amount"
            value={formData.reservation_amount}
            fullWidth
          />
        </Grid>
       
      
      
      </Grid>
    );
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Grid container spacing={2}>
        {/* Company Information */}
        {company && (
          <Grid item xs={12} lg={3}>
            <Paper style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'white',
              padding: '50px',
              alignItems: 'center',
              justifyContent: 'center',
              height: '30vh'
            }}>
              <img alt='alt' src={company.logo} width='50%' />
              <Typography variant='h4' marginTop='30px'>
                {company.name}
              </Typography>
            </Paper>
          </Grid>
        )}

        {/* Reservation Form */}
        <Grid item xs={12} lg={9}>
          {renderOrderForm()}
        </Grid>

        {/* Deal Information */}
        {deal && (
          <Grid item xs={12} lg={12}>
            <Typography variant='body1' sx={{ fontSize: '0.8em' }}>
              Avance does not guarantee the availability of Deals in the future for this fund or any fund.
              To be eligible to sell this position in a Deal, your Reservation Amount must be {deal.minimum_investment_size} GBP or greater.
              You will have the opportunity to review all information prior to signing your subscription agreement and completing the investment process.
              The Reservation Amount that you have entered falls below our investment minimum.
              As such, we have rounded your investment up to the nearest number of shares that satisfies our investment minimum.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ReservationDetails;
