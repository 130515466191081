import React from 'react';
import { useTheme } from '@mui/material/styles';



const LightIcon = ({ IconComponent }) => {

    const theme = useTheme();

    return <IconComponent style={{ 
        color: theme.palette.text.textVeryLight,
        paddingLeft: '10px',
        paddingRight: '10px'
     }} />;
}



const SecondaryIcon = ({ IconComponent }) => {

    const theme = useTheme();

    return <IconComponent style={{ 
        color: theme.palette.secondary.main,
        paddingLeft: '10px',
        paddingRight: '10px'
     }} />;
}




const PrimaryIcon = ({ IconComponent }) => {

    const theme = useTheme();

    return <IconComponent style={{ 
        color: theme.palette.primary.main,
        paddingLeft: '10px',
        paddingRight: '10px'
     }} />;
}

export {LightIcon, SecondaryIcon, PrimaryIcon};