import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import CSRFToken from '../../components/CSRFToken';
import { Close } from '@mui/icons-material';

const VerifyProfile = () => {
    const [files, setFiles] = useState([]);
    const [fileTypes, setFileTypes] = useState({});

    const handleFileChange = e => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
    };

    const handleTypeChange = (index, type) => {
        setFileTypes(prevTypes => ({
            ...prevTypes,
            [index]: type
        }));
    };

    const handleRemoveFile = index => {
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        setFileTypes(prevTypes => {
            const newTypes = { ...prevTypes };
            delete newTypes[index];
            return newTypes;
        });
    };

    const onSubmit = e => {
        e.preventDefault();
        // Placeholder for the file upload logic
        console.log('File upload form submitted:', files, fileTypes);
    };

    return (
        <form onSubmit={e => onSubmit(e)}>
            <CSRFToken />
            <Typography variant="h6" gutterBottom>
                Verify Your Profile
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth margin='normal'>
                        
                        <input
                            id='file_upload'
                            type='file'
                            onChange={handleFileChange}
                            multiple
                            accept='.pdf,.jpg,.png'
                            style={{ display: 'none' }} // Hide the default input
                        />
                        <label htmlFor='file_upload'>
                            <Button
                                variant='outlined'
                                component='span'
                                color='secondary'
                                fullWidth
                            >
                                Choose Files
                            </Button>
                        </label>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {files.length > 0 && (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>File Name</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {files.map((file, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{file.name}</TableCell>
                                            <TableCell>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor={`file_type_${index}`}>Type</InputLabel>
                                                    <Select
                                                        id={`file_type_${index}`}
                                                        value={fileTypes[index] || ''}
                                                        onChange={e => handleTypeChange(index, e.target.value)}
                                                        label="Type"
                                                    >
                                                        <MenuItem value="ID Document">ID Document</MenuItem>
                                                        <MenuItem value="Passport">Passport</MenuItem>
                                                        <MenuItem value="Contract">Contract</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(index)}>
                                                    <Close />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
            </Grid>
            <Button variant="contained" color='secondary' type="submit" sx={{ mt: 2 }}>
                Upload Documents
            </Button>
        </form>
    );
};

export default VerifyProfile;
