import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import api from '../axiosInstance';
import React from 'react'
import { Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
import NewsHeadlines from './NewsHeadlines';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Loading from './Loading';
import { BlobContent } from './Blobs';
import BasicChart from './Chart';
import ReactMarkdown from 'react-markdown';
import { differenceInDays } from 'date-fns';

const NumberFormatter = ({ number }) => {
    const formattedNumber = new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 0,
    }).format(number);

    return <span>{formattedNumber}</span>;
};
const dealTypeMapping = {
    EXP: 'Express',
    STD: 'Standard',
};

const shareClassMapping = {
    COM: 'Common Stock',
};


const calculateDaysToClose = (closingAt) => {
    const closingDate = new Date(closingAt);
    const currentDate = new Date();
    return differenceInDays(closingDate, currentDate);
  };


const CompanyDetails = () => {
    const theme = useTheme();
    const { id } = useParams();
    const navigate = useNavigate();

    const [company, setCompany] = useState(null);
    const [loadingCompany, setLoadingCompany] = useState(true);
    const [errorCompany, setErrorCompany] = useState(null);

    const [deals, setDeals] = useState(null);
    const [setLoadingDeals] = useState(true);
    const [setErrorDeals] = useState(null);

    useEffect(() => {
        const fetchCompanyDetails = async () => {
            try {
                const response = await api.get(`/api/products/companies/${id}/`);
                setCompany(response.data);
                setLoadingCompany(false);
            } catch (error) {
                setErrorCompany(error.message);
                setLoadingCompany(false);
            }
        };

        fetchCompanyDetails();
    }, [id,  setCompany, setLoadingCompany, setErrorCompany]);


    useEffect(() => {
        const fetchDealsPerCompany = async () => {
            try {
                const response = await api.get(`/api/products/deals/${id}/list/`);
                setDeals(response.data);
                setLoadingDeals(false);
            } catch (error) {
                setErrorDeals(error.message);
                setLoadingDeals(false);
            }
        };

        fetchDealsPerCompany();
    }, [id, setDeals, setLoadingDeals, setErrorDeals]);


    const handleInvestNowClick = (dealId) => {
        // Perform any additional logic if needed
        navigate(`/deals/${dealId}`);
    };


    if (loadingCompany) {
        return <div>
            <BlobContent >

                <Loading />


            </BlobContent>
        </div>;
    }

    if (errorCompany) {
        return <div>Error: {errorCompany}</div>;
    }

    return (


        <div>

            <div style={{ padding: 20, backgroundColor: theme.palette.primary.main, color: 'white' }}>
                <BlobContent flexDirection="column" alignItems="center">
                    <Typography variant="h3" gutterBottom>
                        Private Company Markeplace
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        The biggest marketplace for buyers and sellers of Europe's hottest startups

                    </Typography>

                    <Button variant="contained" component={Link} >
                        14 Days until a new listing
                    </Button>

                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                        <Button variant="contained" color='secondary' component={Link} to="/investments" >
                            How it works
                        </Button>
                        <Button variant="contained" color='secondary' component={Link} to="/investments" >
                            Join our waitlist
                        </Button>
                    </Box>
                </BlobContent>
            </div>

            <Grid container display='flex' spacing={2} justifyContent={'center'} padding='40px' >

                {/* BACK TO COMPANIES LIST */}
                <Grid item xs={12} lg={10} style={{ justifyContent: 'flex-start', padding: '10px' }}>
                    <Button variant="text" startIcon={<KeyboardBackspaceIcon />} onClick={() => navigate(-1)}>
                        Back to companies list
                    </Button>

                </Grid>

                {/* COMPANY SUMMARY */}
                <Grid container item xs={12} lg={10} component={Paper} flexDirection="row" alignItems="flex-start">

                    <Grid item xs={12} lg={2}>
                        <img alt='logo' src={company.logo} style={{ margin: '40px', borderRadius: 5, height: '10vw' }} />
                    </Grid>

                    <Grid item xs={12} lg={8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingTop: '40px' }}>
                        <Typography variant="h3" style={{ color: 'black', padding: '20px' }}>
                            {company.name}
                        </Typography>

                        <Grid container spacing={2} style={{ padding: '20px', display: "flex", width: '60vw', justifyContent: 'flex-start', backgroundColor: '#f7f7f7', borderRadius: '20px', textAlign: 'left' }}>


                            <Grid item xs={12} lg={2} >
                                <Typography variant="h6">Founded</Typography>
                                <Typography variant="body1">
                                    {company.founded_date}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={3}>
                                <Typography variant="h6">Notable Investors</Typography>
                                <Typography variant="body1">
                                    {Array.isArray(company.notable_investors)
                                        ? company.notable_investors.join(', ')
                                        : 'No investors listed'}
                                </Typography>
                            </Grid>


                            <Grid item xs={12} lg={3} >
                                <Typography variant="h6">Headquarters</Typography>
                                <Typography variant="body1" >
                                    {company.headquarters}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} lg={3}>
                                <Typography variant="h6">Total Funding</Typography>
                                <Typography variant="body1">

                                    {company.currency} <NumberFormatter number={company.total_funding} />
                                </Typography>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* LIVE DEALS */}
                <Grid item xs={12} lg={10} flexDirection="column" alignItems="left">

                    <Typography variant="h4" style={{ color: 'black', marginBottom: 20 }}>
                        Live Deals
                    </Typography>


                    <Box component={Paper} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%' }}>
                        <TableContainer component={Paper} style={{ padding: '40px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow style={{ borderBottom: '2px solid black' }}>
                                        <TableCell>Deal Type</TableCell>
                                        <TableCell>Share Class</TableCell>
                                        <TableCell>Implied Share Price</TableCell>
                                        <TableCell>Implied Valuation</TableCell>
                                        <TableCell>Premium/Discount to Last Valuation</TableCell>
                                        <TableCell>Minimum Investment Size</TableCell>
                                        <TableCell>Time to close</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ color: 'grey' }}>
                                    {deals === null ? (
                                        <TableRow>
                                            <TableCell colSpan={8} align="center">
                                                Loading...
                                            </TableCell>
                                        </TableRow>
                                    ) : deals.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={8} align="center">
                                                No deals available
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        deals.map((deal) => (
                                            <TableRow key={deal.id}>
                                                <TableCell>{dealTypeMapping[deal.type]}</TableCell>
                                                <TableCell>{shareClassMapping[deal.share_class]}</TableCell>
                                                <TableCell>${deal.implied_share_price}</TableCell>
                                                <TableCell>${deal.implied_valuation}</TableCell>
                                                <TableCell>{deal.discount_to_last_val}%</TableCell>
                                                <TableCell>$<NumberFormatter number={deal.minimum_investment_size} /></TableCell>
                                                <TableCell>{calculateDaysToClose(deal.closing_at)} days</TableCell>
                                                <TableCell>
                                                    <Button variant="contained" color="secondary" onClick={() => handleInvestNowClick(deal.id)}>
                                                        Invest Now
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>

                {/* ABOUT THE STOCK */}
                <Grid item xs={12} lg={10} flexDirection="column" alignItems="left">

                    <Typography variant="h4" style={{ marginBottom: 20 }}>
                        About The Stock
                    </Typography>
                    <Box component={Paper} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '40px' }}>

                        <ReactMarkdown>{company.description}</ReactMarkdown>
                    </Box>
                </Grid>

                {/* FINANCIALS */}
                <Grid item xs={12} lg={10} style={{
                    display: 'flex',
                    flexDirection: 'column',

                }}>

                    <Typography variant="h4" style={{ color: 'black', marginBottom: 20 }}>
                        Financials
                    </Typography>

                    <Typography>
                        As of 07/06/2024
                    </Typography>

                    <BasicChart />


                    <Grid container padding={2} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',

                    }}>
                        <Grid item xs={10} lg={2} component={Paper} style={{ backgroundColor: theme.palette.secondary.light, margin: '40px', padding: '20px' }}>
                            <Typography variant='h4' style={{ color: theme.palette.secondary.main }}>$8.8B</Typography>
                            <Typography>Latest funding round valuation</Typography>
                        </Grid>

                        <Grid item xs={10} lg={2} component={Paper} style={{ backgroundColor: theme.palette.secondary.light, margin: '40px', padding: '20px' }}>
                            <Typography variant='h4' style={{ color: theme.palette.secondary.main }}>$20.45</Typography>
                            <Typography>Latest funding round price</Typography>
                        </Grid>

                        <Grid item xs={10} lg={2} component={Paper} style={{ backgroundColor: theme.palette.secondary.light, margin: '40px', padding: '20px' }}>
                            <Typography variant='h5' style={{ color: theme.palette.secondary.main }}>Series H Preferred Stock</Typography>
                            <Typography>Latest funding round security</Typography>
                        </Grid>

                        <Grid item xs={10} lg={2} component={Paper} style={{ backgroundColor: theme.palette.secondary.light, margin: '40px', padding: '20px' }}>
                            <Typography variant='h4' style={{ color: theme.palette.secondary.main }} >$1.1B</Typography>
                            <Typography >Cumulative funding</Typography>
                        </Grid>

                    </Grid>

                </Grid>

                {/* funding history */}
                <Grid item xs={12} lg={10} flexDirection="column" alignItems="left">

                    <Typography variant="h5" style={{ color: 'black', marginBottom: 20 }}>
                        Funding history
                    </Typography>

                    <Box component={Paper} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%' }}>

                        <TableContainer component={Paper} style={{ padding: '40px' }} s>
                            <Table >
                                <TableBody style={{ color: 'grey' }}>

                                    <TableRow>
                                        <TableCell>August 2021</TableCell>
                                        <TableCell>$13.7M</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell>August 2021</TableCell>
                                        <TableCell>$5.5M</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell>July 2021</TableCell>
                                        <TableCell>$800M</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell>July 2020</TableCell>
                                        <TableCell>$80M</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell>February 2020</TableCell>
                                        <TableCell>$500M</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell>April 2018</TableCell>
                                        <TableCell>$250M</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell>August 2017</TableCell>
                                        <TableCell>$3.8M</TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>




                </Grid>

                {/* CAPITALISATION TABLE */}
                <Grid item xs={12} lg={10} flexDirection="column" alignItems="left" marginTop='20px'>
                    <Typography variant="h4" style={{ color: 'black', marginBottom: 20 }}>
                        Capitalization table
                    </Typography>
                    <Typography variant="body1" paddingBottom='20px'>
                        This capitalization table has been updated as of Mar 2024.
                        <br>
                        </br>
                        <br>
                        </br>
                        Based on their recent round of financing {company.name} has a post-money valuation of approximately $4.7B.
                        <br>
                        </br>
                        <br>
                        </br>
                        Using proprietary analysis, Avance has reconstructed the {company.name} Cap Table to get an overview of the various share classes and liquidity preferences. "Valuation" and "Shares Outstanding" figures are estimates of Avance. All other information below is based on publicly available information, including media reports, regulatory filings, and press releases.

                        Our capitalization tables are primarily based on public filings, which companies are generally required to complete after new funding rounds. Avance strives to incorporate all publicly available sources of information in our investment research. In some cases, though, companies may have outstanding debt instruments or other claims on company assets that are not readily disclosed or otherwise to us. These claims may be senior to equity claims and could impact your return.
                    </Typography>

                    <Box component={Paper} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%' }}>

                        <TableContainer component={Paper} style={{ padding: '40px' }} s>
                            <Table >
                                <TableHead >
                                    <TableRow style={{ borderBottom: '2px solid black' }}>
                                        <TableCell>Share Class</TableCell>
                                        <TableCell>Date of financing </TableCell>
                                        <TableCell>Shares Outstanding</TableCell>
                                        <TableCell>Issue price</TableCell>
                                        <TableCell>Conversion Price </TableCell>
                                        <TableCell>Total financing size</TableCell>
                                        <TableCell>Liquidation Preference</TableCell>
                                        <TableCell>Liquidity Rank</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ color: 'grey' }}>

                                    <TableRow>
                                        <TableCell>Class A Common Stock</TableCell>
                                        <TableCell>1/1/2009</TableCell>
                                        <TableCell>44.6M</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Class A Common Stock</TableCell>
                                        <TableCell>1/1/2009</TableCell>
                                        <TableCell>44.6M</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Class A Common Stock</TableCell>
                                        <TableCell>1/1/2009</TableCell>
                                        <TableCell>44.6M</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>5</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Class A Common Stock</TableCell>
                                        <TableCell>1/1/2009</TableCell>
                                        <TableCell>44.6M</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>$0.00</TableCell>
                                        <TableCell>5</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>






                </Grid>

                {/* PRESS */}

                <Grid item xs={12} lg={10} flexDirection="column" alignItems="left" marginTop='20px'>
                    <Typography variant="h4" style={{ color: 'black', marginBottom: 20 }}>
                        Press
                    </Typography>

                    <Box component={Paper} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%' }}>

                        <NewsHeadlines companyName={company.name} />

                    </Box>


                </Grid>

            </Grid>

        </div>
    );
};

export default CompanyDetails;
