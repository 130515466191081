import api from '../axiosInstance';
import Cookies from 'js-cookie';
import { load_investor_profile } from './profile';

import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL
} from './types';


export const checkAuthenticated = () => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }

    try {

        const res = await api.get('api/accounts/authenticated', config);

        if (res.data.error || res.data.isAuthenticated === 'error') {
            dispatch({
                type: AUTHENTICATED_FAIL,
                payload: false
            });
        }
        else if (res.data.isAuthenticated === 'success') {
            dispatch({
                type: AUTHENTICATED_SUCCESS,
                payload: true
            })
        }
        else {

            dispatch({
                type: AUTHENTICATED_FAIL,
                payload: false
            });

        }
    } catch (err) {

        dispatch({
            type: AUTHENTICATED_FAIL,
            payload: false
        });

    }
}



export const login = (email, password) => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }
    
    const body = JSON.stringify({
        email,
        password,
        
    });

    try {

        const res = await api.post('api/accounts/login', body, config);

        if (res.data.success) {
            dispatch({
                type: LOGIN_SUCCESS
            });
            
            dispatch(load_investor_profile());

        } else {
            dispatch({
                type: LOGIN_FAIL
            });
        }

    } catch (err) {
        
        dispatch({
            type: LOGIN_FAIL,
        });
    }
}


export const register = (email, password, re_password) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    };

    const body = JSON.stringify({
        email,
        password,
        re_password
    });

    try {
        const res = await api.post('/api/accounts/register', body, config);

        if (res.data.error) {
            dispatch({
                type: REGISTER_FAIL
            });
        }
        else {
            dispatch({
                type: REGISTER_SUCCESS
            })
        }

    } catch (err) {
        dispatch({
            type: REGISTER_FAIL
        })
    }

};



export const logout = () => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    }

    const body = JSON.stringify({
        'withCredentials': true
    })

    try {

        const res = await api.post('/api/accounts/logout', body, config);

        if (res.data.success) {
            dispatch({
                type: LOGOUT_SUCCESS,
            });
        } else {
            dispatch({
                type: LOGOUT_FAIL,
            });
        }

    } catch (err) {
        dispatch({
            type: LOGOUT_FAIL,
        });
    }
}
