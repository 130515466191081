import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Grid } from '@mui/material';
import { Facebook, X, LinkedIn, Instagram, LocationOn, Phone, Email } from '@mui/icons-material';
import images from '../images';
import { SecondaryIcon } from './ColouredIcons';

const Footer = () => {
    const theme = useTheme();

    return (


        <Box style={{
            backgroundColor: theme.palette.background.dark,
            color: theme.palette.text.light,

            position: 'relative',
            paddingTop: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'

        }}>

            <Grid container spacing={0} style={{
                display: 'flex',
                flexDirection: 'row',

                paddingBottom: '40px',
                paddingTop: '20px',
            }}>

                <Grid item xs={0} lg={1}>

                </Grid>
                {/* Logo Column */}
                <Grid item xs={12} lg={1}>
                    <img src={images.logo} alt="Company Logo" style={{
                        maxHeight: '70px',
                        maxWidth: '200px',
                        height: 'auto',
                        padding: '20px'
                    }} />

                    <Box style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        paddingTop: '20px',
                        width: '150px',
                        marginTop: theme.spacing(2),
                    }}>

                        <a href="https://www.facebook.com/"> <SecondaryIcon variant='secondary' IconComponent={Facebook} /> </a>
                        <a href="https://www.twitter.com/">  <SecondaryIcon variant='secondary' IconComponent={X} /> </a>
                        <a href="https://www.linkedin.com/"> <SecondaryIcon variant='secondary' IconComponent={LinkedIn} /> </a>
                        <a href="https://www.instagram.com/"> <SecondaryIcon variant='secondary' IconComponent={Instagram} /> </a>
                    </Box>


                </Grid>
                <Grid item xs={0} lg={1}>

                </Grid>

                {/* Company Column */}
                <Grid item xs={12} lg={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="h6" color="inherit" style={{ fontWeight: 'bold', marginBottom: '16px' }}>Company</Typography>
                    <Typography variant="darkBg" component="a" href="/about">Who we are</Typography>
                    <Typography variant="darkBg" component="a" href="/careers">Career</Typography>
                    <Typography variant="darkBg" component="a" href="/press">Press</Typography>
                    <Typography variant="darkBg" component="a" href="/help">Help</Typography>
                </Grid>
                <Grid item xs={0} lg={1}>

                </Grid>
                {/* Policy Column */}
                <Grid item xs={12} lg={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >
                    <Typography variant="h6" color="inherit" style={{ fontWeight: 'bold', marginBottom: '16px' }}>Policy & Disclosures</Typography>

                    <Typography variant="darkBg" component="a" href={`/documents/rulebooks/${encodeURIComponent('Disclosures')}`}> Disclosures </Typography>
                    <Typography variant="darkBg" component="a" href={`/documents/rulebooks/${encodeURIComponent('Business Continuity')}`}> Business Continuity </Typography>
                    <Typography variant="darkBg" component="a" href={`/documents/rulebooks/${encodeURIComponent('Privacy Policy')}`}> Privacy Policy </Typography>
                    <Typography variant="darkBg" component="a" href={`/documents/rulebooks/${encodeURIComponent('Allocation Policy')}`}> Allocation Policy </Typography>
                </Grid>
                <Grid item xs={0} lg={1}>

                </Grid>

                {/* Get in Touch Column */}
                <Grid item xs={12} lg={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="h6" color="inherit" style={{ fontWeight: 'bold', marginBottom: '16px' }}>Get in Touch with Us</Typography>

                    <Box style={{ display: "flex", flexDirection: 'row', alignItems: 'flex-start' }}>
                        <SecondaryIcon variant='secondary' IconComponent={LocationOn} />
                        <Typography variant="darkBg">
                            71-75 Shelton Street
                            <br></br> Covent Garden
                            <br></br> London WC2H 9JQ
                            <br></br> United Kingdom
                        </Typography>
                    </Box>

                    <Box style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                        <SecondaryIcon variant='secondary' IconComponent={Phone} />
                        <Typography variant="darkBg"> 394-091-3312 </Typography>
                    </Box>

                    <Box style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                        <SecondaryIcon variant='secondary' IconComponent={Email} />
                        <Typography variant="darkBg" component="a" href={`mailto:support@avance.com`}> support@avance.com </Typography>
                    </Box>

                </Grid>

                <Grid item xs={0} lg={2}>

                </Grid>
            </Grid>

            <Grid container display='flex' spacing={2} justifyContent={'center'} fontSize={'14px'} >
                {/* Bottom Line */}
                <Grid item xs={10} lg={10}>
                    <Typography variant="body2" style={{ marginTop: '16px', textAlign: 'left' }}>
                        Investment opportunities posted on this website are "private placements" of securities that are not publicly traded, are subject to holding period requirements, and are intended for investors who do not need a liquid investment. Investing in private companies may be considered highly speculative and involves a high degree of risk, including the risk of substantial loss of investment. Investors must be able to afford the loss of their entire investment. See our Risk Factors for a more detailed explanation of the risks involved by investing through Avance’s platform.
                    </Typography>

                    <Typography variant="body2" style={{ marginTop: '16px', textAlign: 'left' }}>
                        Avance Ltd is an appointed representative of ODIN Capital Management Ltd registered with the FCA under number XXXXX. Avance.com is a website operated by Avance Ltd ("Avance"). By accessing this site and any pages thereof, you agree to be bound by our <Typography variant='darkBg' component="a"
                            href={`/documents/rulebooks/${encodeURIComponent('Terms & Conditions')}`} >Terms & Conditions </Typography>. Avance logo are trademarks of Avance Ltd. Other trademarks are property of their respective owners.
                    </Typography>

                </Grid>

            </Grid>


            <Typography variant="darkBg" padding='10px' fontSize={'12px'} textAlign={'center'}>
                Copyright © 2024 Avance Ltd. All rights reserved.
            </Typography>


        </Box>


    );
};

export default Footer;
