import React from 'react';
import {  Typography, Button, Container, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Visualiser() {
    const theme = useTheme();

    return (
        <Container maxWidth="lg" style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
            Theme Elements Visualisation
        </Typography>

        {/* Default Background Section */}
        <Paper elevation={3} variant='default' style={{ display:'flex',alignItems:'center', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
                Default Background
            </Typography>

            <Button variant="bright" color="primary">
                Bright Primary
            </Button>
            <Button variant="bright" color="secondary">
                Bright secondary
            </Button>
           

            <Button variant="contained" color="primary">
                Contained Primary
            </Button>
            <Button variant="outlined" color="primary">
                Outlined Primary
            </Button>
           
            <br></br>
            <Button variant="contained" color="secondary">
                Contained Secondary
            </Button>
            <Button variant="outlined" color="secondary">
                Outlined Secondary
            </Button>
            <br></br>
            <Button variant="text" color="primary">
                Text Primary
            </Button>
            <Button variant="text" color="secondary">
                Text Secondary
            </Button>
            <Typography variant="body1" color="textPrimary" gutterBottom>
                Primary Text Color
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Secondary Text Color
            </Typography>
            <Typography variant="body1" style={{ color: theme.palette.text.light }}>
                Light Text Color
            </Typography>
        </Paper>

        {/* Paper Background Section */}
        <Paper elevation={3} variant='paper' style={{ display:'flex',alignItems:'center', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
                Paper Background
            </Typography>

            <Button variant="bright" color="primary">
                Bright Primary
            </Button>
            <Button variant="bright" color="secondary">
                Bright secondary
            </Button>
           
            <Button variant="contained" color="primary">
                Contained Primary
            </Button>
            <Button variant="outlined" color="primary">
                Outlined Primary
            </Button>
           
            <br></br>
            <Button variant="contained" color="secondary">
                Contained Secondary
            </Button>
            <Button variant="outlined" color="secondary">
                Outlined Secondary
            </Button>
            <br></br>
            <Button variant="text" color="primary">
                Text Primary
            </Button>
            <Button variant="text" color="secondary">
                Text Secondary
            </Button>
            <Typography variant="body1" color="textPrimary" gutterBottom>
                Primary Text Color
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Secondary Text Color
            </Typography>
            <Typography variant="body1" style={{ color: theme.palette.text.light }}>
                Light Text Color
            </Typography>
        </Paper>

        {/* Dark Background Section */}
        <Paper elevation={3} variant='dark' style={{ display:'flex',alignItems:'center', flexDirection: 'column' }}>
            <Typography variant="h6" style={{ color: theme.palette.text.light }} gutterBottom>
                Dark Background
            </Typography>
            
            <Button variant="bright" color="primary">
                Bright Primary
            </Button>
            <Button variant="bright" color="secondary">
                Bright secondary
            </Button>
           
            <Button variant="contained" color="primary">
                Contained Primary
            </Button>
            <Button variant="outlined" color="primary">
                Outlined Primary
            </Button>
           
            <br></br>
            <Button variant="contained" color="secondary">
                Contained Secondary
            </Button>
            <Button variant="outlined" color="secondary">
                Outlined Secondary
            </Button>
            <br></br>
            <Button variant="text" color="primary">
                Text Primary
            </Button>
            <Button variant="text" color="secondary">
                Text Secondary
            </Button>
            <Typography variant="body1" color="textPrimary" gutterBottom>
                Primary Text Color
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Secondary Text Color
            </Typography>
            <Typography variant="body1" style={{ color: theme.palette.text.light }}>
                Light Text Color
            </Typography>
        </Paper>

         {/* Primary Color Background Section */}
         <Paper elevation={3} variant='primary' style={{ display:'flex',alignItems:'center', flexDirection: 'column' }}>
                    <Typography variant="h6" style={{ color: theme.palette.text.light }} gutterBottom>
                        Primary Color Background
                    </Typography>

                    <Button variant="bright" color="primary">
                Bright Primary
            </Button>
            <Button variant="bright" color="secondary">
                Bright secondary
            </Button>
           
                    <Button variant="contained" color="primary">
                Contained Primary
            </Button>
            <Button variant="outlined" color="primary">
                Outlined Primary
            </Button>
           
            <br></br>
            <Button variant="contained" color="secondary">
                Contained Secondary
            </Button>
            <Button variant="outlined" color="secondary">
                Outlined Secondary
            </Button>
            <br></br>
            <Button variant="text" color="primary">
                Text Primary
            </Button>
            <Button variant="text" color="secondary">
                Text Secondary
            </Button>
            <Typography variant="body1" color="textPrimary" gutterBottom>
                Primary Text Color
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
                Secondary Text Color
            </Typography>
            <Typography variant="body1" style={{ color: theme.palette.text.light }}>
                Light Text Color
            </Typography>
                </Paper>
    </Container>
    );
}

export default Visualiser;
