import React, { useState } from 'react';
import { register } from '../actions/auth';
import { connect } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import { Typography, Button, Box, Paper } from '@mui/material';
import CSRFToken from '../components/CSRFToken';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import {BlobContent} from '../components/Blobs';
import { useTheme } from '@emotion/react';
import Divider from '@mui/material/Divider';
import images from '../images';

const Register = ({ register, isAuthenticated }) => {

    const theme = useTheme();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        re_password: ''
    });

    const [accountCreated, setAccountCreated] = useState(false);
    const { email, password, re_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        if (password === re_password) {

            register(email, password, re_password);
            setAccountCreated(true);
        }
    };

    if (isAuthenticated){
        return <Navigate to='/investments' />;
    }

    else if (accountCreated) {
        return (
        <div style={{ padding: 20, backgroundColor: theme.palette.primary.main, color: 'white' }}>
        <BlobContent flexDirection="column" alignItems="center">
            <Box component={Paper} style={{ padding: '60px' }}>
                <Typography variant='h4' gutterBottom> Your account has been created. </Typography>
                <Typography varian='body1'>
                    Please click in the link we have sent to your email to activate your account.
                </Typography>

        </Box>
</BlobContent>
    </div>
        )
    }


    return (
        <div style={{ padding: 20, backgroundColor: theme.palette.primary.main, color: 'white' }}>
            <BlobContent flexDirection="column" alignItems="center">
                <Box component={Paper} style={{ padding: '60px' }}>
                    <Typography variant='h4' gutterBottom> Register to start investing </Typography>

                    <form onSubmit={e => onSubmit(e)} style={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center' }}>

                       <CSRFToken/>

                    <FormControl variant='outlined' fullwidth margin='normal' required>
                        <InputLabel htmlFor='email'>Email address</InputLabel>
                        <OutlinedInput
                            id='email'
                            name='email'
                            label='Email'
                            value={email}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>

                    <FormControl variant='outlined' fullwidth margin='normal' required>
                        <InputLabel htmlFor='password'>Password</InputLabel>
                        <OutlinedInput
                            id='password'
                            name='password'
                            label='Password'
                            type='password'
                            value={password}
                            onChange={e => onChange(e)}
                        />
                    </FormControl>                        

                    <FormControl variant='outlined' fullwidth margin='normal' required>
                        <InputLabel htmlFor='re_password'>Confirm Password</InputLabel>
                        <OutlinedInput
                            id='re_password'
                            name='re_password'
                            label='Confirm Password'
                            type='password'
                            minLength='6'
                            value={re_password}
                            onChange={e => onChange(e)}
                        />

                    </FormControl>

                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        fullWidth
                    >
                        Sign Up
                    </Button>
                </form>


                <Divider>or</Divider>
                
                <Box style={{

                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>

                    <Typography variant='body2' gutterBottom>
                            By clicking Continue to join or sign in, you agree to Avance's User Agreement, Privacy Policy & Cookie Policy.
                    </Typography>


                    <Button
                            variant="bright"
                            color="secondary"
                            startIcon={<img src={images.google} alt="Google Icon"></img>}
                            fullWidth
                            style={{
                                marginTop: '30px',

                            }}
                        >
                            Sign up with Google
                        </Button>


                        <Typography variant='body1'
                            style={{ marginTop: '30px' }}
                        >
                            Already have an account?
                        </Typography>

                        <Button
                            variant="contained"
                            color='secondary'
                            component={Link}
                            to="/login"
                            style={{ marginTop: '30px' }}
                            fullWidth >
                            Login Here
                        </Button>
                </Box>
            </Box>
        
        </BlobContent>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.isAuthenticated
})

export default connect(mapStateToProps, { register })(Register);