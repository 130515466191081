import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL
} from '../actions/types';


const initialState = {
    isAuthenticated: null
};



const auth = function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {

        case AUTHENTICATED_FAIL:
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: payload
            }
        case REGISTER_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false
            }
        
        case LOGOUT_FAIL:
        case LOGIN_FAIL:
        case REGISTER_FAIL:
            return state
        default:
            return state

    };
}

export default auth;