import React, { useState } from 'react';
import { login } from '../actions/auth';
import { connect } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import CSRFToken from '../components/CSRFToken';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { Typography, Button, Box, Paper } from '@mui/material';
import { BlobContent } from '../components/Blobs';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import images from '../images';

const Login = ({ login, isAuthenticated }) => {

    const theme = useTheme();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const { email, password } = formData;



    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        login(email, password);

    };




    if (isAuthenticated) {
        return <Navigate to='/investments' />;
    }

    return (

        <div style={{ padding: 20, backgroundColor: theme.palette.primary.main, color: 'white' }}>
            <BlobContent flexDirection="column" alignItems="center">
                <Box component={Paper} style={{ padding: '60px' }}>
                    <Typography variant='h4' gutterBottom> Welcome to your professional community </Typography>

                    <form onSubmit={e => onSubmit(e)} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>

                        <CSRFToken />

                        <FormControl variant='outlined' fullWidth margin='normal' required>
                            <InputLabel htmlFor='email'>Email</InputLabel>
                            <OutlinedInput
                                id='email'
                                name='email'
                                label='Email'
                                value={email}
                                onChange={e => onChange(e)}
                            />
                        </FormControl>

                        <br></br>
                        <FormControl variant='outlined' fullWidth margin='normal' required>
                            <InputLabel htmlFor='password'>Password</InputLabel>
                            <OutlinedInput
                                id='password'
                                name='password'
                                label='Password'
                                type='password'
                                value={password}
                                onChange={e => onChange(e)}
                            />
                        </FormControl>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Login
                        </Button>
                    </form>

                    <Divider>or</Divider>
                    <Box style={{

                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>

                    <Typography variant='body2' gutterBottom>
                            By clicking Continue to join or sign in, you agree to Avance's User Agreement, Privacy Policy & Cookie Policy.
                    </Typography>


                    <Button
                            variant="bright"
                            color="primary"
                            startIcon={<img src={images.google} alt="Google Icon"></img>}
                            fullWidth
                            style={{
                                marginTop: '30px',

                            }}
                        >
                            Login with Google
                        </Button>


                        <Typography variant='body1'
                            style={{ marginTop: '30px' }}
                        >
                            Don't Have An Account Yet?
                        </Typography>

                        <Button
                            variant="contained"
                            color='primary'
                            component={Link}
                            to="/register"
                            style={{ marginTop: '30px' }}
                            fullWidth >
                            Sign Up Here
                        </Button>
                    </Box>
                </Box>
            </BlobContent>
        </div>
    )



};
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);