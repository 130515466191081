import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Typography, Button, Box, TextField, TableContainer, Paper, Grid
} from '@mui/material';
import api from '../axiosInstance';
import { BlobContent } from '../components/Blobs';
import Loading from '../components/Loading';
import { Filter, NumericFilterForValuation } from '../components/Filter';
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import '../App.css'; 
import DealStatusButton from '../components/DealStatusButton';
import Cookies from 'js-cookie';


const DealStatusFilter = ({ value, onChange }) => {
    const fetchOptionsUrl = '/api/products/companies/filter-options/deal_status/';

    return (
        <Filter
            label="Deal Status"
            value={value}
            onChange={onChange}
            fetchOptionsUrl={fetchOptionsUrl}
        />
    );
};

const SectorFilter = ({ value, onChange }) => {
    const fetchOptionsUrl = '/api/products/companies/filter-options/sector/';

    return (
        <Filter
            label="Sector"
            value={value}
            onChange={onChange}
            fetchOptionsUrl={fetchOptionsUrl}
        />
    );
};


const ValuationNumericFilter = ({ selectedValue, onChange }) => {

    const limit1 = 10000000; //10M
    const limit2 = 1000000000; // 1B

    return (
        <NumericFilterForValuation
            label="Valuation Range"
            limit1={limit1}
            limit2={limit2}
            selectedValue={selectedValue}
            onChange={onChange}

        />
    );
};


const SearchBar = ({ searchQuery, setSearchQuery }) => {
    return (
        <Box display="flex" justifyContent="flex-end" mb={2} style={{ padding: '20px' }}>
            <TextField
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search by name..."
                size="small"
            />
        </Box>
    );
};


const NumberFormatter = ({ number }) => {
    const formattedNumber = new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 0,
    }).format(number);

    return <span>{formattedNumber}</span>;
};


const Investments = ({isAuthenticated}) => {
    const navigate = useNavigate();

    const theme = useTheme();
    const [selectedValue, setSelectedValue] = useState('ALL');
    const [searchQuery, setSearchQuery] = useState('');
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [dealStatusFilter, setDealStatusFilter] = useState('ALL');
    const [valuationRange, setValuationRange] = useState({ min: 0, max: Infinity });
    const [sectorFilter, setSectorFilter] = useState('ALL');



    const columns = [
        {
            field: 'logo',
            headerName: '',
            renderCell: (params) => (
                <img alt='logo' src={params.value} style={{
                    maxHeight: '50px',
                    maxWidth: '100%',
                    borderRadius: 5,
                    padding: '10px'
    
                }} />
            ),
            sortable: false,
            filterable: false,
            width: 60
        },
    
        {
            field: 'name',
            headerName: 'Company',
            width: 130
        },
    
        {
            field: 'currency',
            headerName: 'Currency',
            width: 130
        },
        {
            field: 'indicative_valuation',
            headerName: 'Indicative Valuation',
            renderCell: (params) => <NumberFormatter number={params.value} />,
            width: 200
        },
        {
            field: 'change_percentage',
            headerName: '% change',
            width: 130
        },
        {
            field: 'num_investors',
            headerName: '# of investors',
            renderCell: (params) => <NumberFormatter number={params.value} />,
            width: 130
        },
        {
            field: 'num_available_shares',
            headerName: 'Available shares',
            renderCell: (params) => <NumberFormatter number={params.value} />,
            width: 180
        },
        {
            field: 'deal_status',
            headerName: 'Deal Status',
            renderCell: (params) => <DealStatusButton status={params.value} onClick={(status) => handleDealStatusClick(status, params.row.id)}/>,
            width: 200
        },
    ];
    
    
    

    const handleFilterChange = (value) => {

        setSelectedValue(value);
        switch (value) {
            case 'less_than_10000000':
                setValuationRange({ min: 0, max: 10000000 });
                break;
            case 'between_10000000_and_1000000000':
                setValuationRange({ min: 10000000, max: 1000000000 });
                break;
            case 'more_than_1000000000':
                setValuationRange({ min: 1000000000, max: Infinity });
                break;
            case 'ALL':
                setValuationRange({ min: 0, max: Infinity });
                break;
            default:
                setValuationRange({ min: 0, max: Infinity });
        }
    }




    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await api.get('/api/products/companies/');
                setCompanies(response.data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchCompanies();
    }, []);
    
   
    const handleDealStatusClick = async (status, companyId) => {
        
        try {
            
            if (status === 'invest_now') {
                navigate(`/company/${companyId}`);
            } else if (status === 'waiting_list') {
                try {


                    // Fetch available deals for the company
                    const dealsResponse = await api.get(`/api/products/deals/${companyId}/list/`);
                    
                    // Check if there are any available deals
                    if (dealsResponse.data.length === 0) {
                        alert('No available deals found.');
                        return;
                    }
    
                    // Get the ID of the first available deal
                    const firstDealId = dealsResponse.data[0].id;
                    const config = {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-CSRFToken': Cookies.get('csrftoken')
                        }
                    }
                    // Join the waiting list using the first available deal ID
                const waitlistResponse = await api.post(`/api/products/waiting_list/${firstDealId}/`, {}, config);

                // Display the response from the server on success
                alert(`${waitlistResponse.data.message || 'You have been signed up for the waiting list.'}`);
            } catch (error) {
                // Catch all errors, log to console, and show a generic alert
                console.error('Error joining the waiting list:', error);
                alert('Something went wrong. Please try again.');
            }
        }
    } catch (error) {
        // Catch all errors related to fetching deals, log to console, and show a generic alert
        console.error('Error fetching available deals:', error);
        alert('Something went wrong. Please try again.');
    }
};
    if (loading) {

        <div style={{ padding: 20, backgroundColor: theme.palette.primary.light, color: 'white' }}>
            <BlobContent flexDirection="column" alignItems="center">
                <Loading />
            </BlobContent>
        </div>;
    }

    if (error) {
        <div style={{ padding: 20, backgroundColor: theme.palette.primary.light, color: 'white' }}>
            <BlobContent flexDirection="column" alignItems="center">
                Error: {error}
            </BlobContent>
        </div>;
    }
    const filteredCompanies = companies.filter(company => {
        const matchesSearch = company.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesDealStatus = dealStatusFilter === 'ALL' || company.deal_status === dealStatusFilter;
        const matchesSector = sectorFilter === 'ALL' || company.sector === sectorFilter;
        const matchesValuation = company.indicative_valuation >= valuationRange.min && company.indicative_valuation <= valuationRange.max;

        return matchesSearch && matchesDealStatus && matchesValuation && matchesSector;
    });


    const rows = filteredCompanies.map((company) => ({
        id: company.id,
        logo: company.logo,
        name: company.name,
        currency: company.currency,
        indicative_valuation: company.indicative_valuation,
        change_percentage: company.change_percentage,
        num_investors: company.num_investors,
        num_available_shares: company.num_available_shares,
        deal_status: company.deal_status,
    }));

    return (




        <div>

            <div style={{ padding: 20, backgroundColor: theme.palette.primary.main, color: 'white' }}>
                <BlobContent flexDirection="column" alignItems="center">
                    <Typography variant="h3" gutterBottom>
                        Private Company Marketplace
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        The biggest marketplace for buyers and sellers of Europe's hottest startups

                    </Typography>

                    <Button variant="bright" color='secondary' component={Link}>
                        14 Days until a new listing
                    </Button>

                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                        <Button variant="contained" color='secondary' component={Link} to="/howitworks" >
                            How it works
                        </Button>
                        <Button variant="contained" color='secondary' component={Link} to="/login" >
                            Join our waitlist
                        </Button>
                    </Box>
                </BlobContent>


            </div>

            <div>
                <Grid container display='flex' spacing={2} justifyContent={'center'} padding='40px' >
                    <TableContainer item xs={12} lg={10} component={Paper}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '80%',
                            overflowX: 'auto',
                            maxHeight: 'calc(100vh - 64px)',
                            padding: '20px',
                            justifyContent: 'center',
                        }} >
                        <Box  >
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, padding: '20px' }}>

                                    <DealStatusFilter value={dealStatusFilter} onChange={setDealStatusFilter} />
                                    <SectorFilter value={sectorFilter} onChange={setSectorFilter} />
                                    <ValuationNumericFilter
                                        selectedValue={selectedValue}
                                        onChange={handleFilterChange}
                                    />                                </Box>
                                <Box sx={{ display: 'flex', mb: 2, padding: '20px' }}>
                                    <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

                                </Box>
                            </Box>


                            <Box sx={{ height: 400, width: '100%' }} >
                                <DataGrid
                                    rows={rows}
                                    columns={columns.map(col => {
                                        if (col.field !== 'logo' && col.field  !== 'name' && !isAuthenticated) {
                                          return {
                                            ...col,
                                            renderCell: params => (
                                              <div className="blur">{params.value}</div>
                                            ),
                                          };
                                        }
                                        return col;
                                      })}
                                    rowHeight={70} 
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 5,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[5]}
                                    disableColumnMenu
                                    onRowClick={(params, event) => event.stopPropagation()} 
                                />
                            </Box>


                        </Box>
                    </TableContainer>
                </Grid>

            </div>

        </div>
    );
};

const mapStateToProps = state => ({

    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps,)(Investments);
