import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, FormControl, InputLabel, OutlinedInput, Grid, Button, Typography, IconButton
} from '@mui/material';
import { connect } from 'react-redux';
import { create_banking_profile, load_banking_profiles, update_banking_profile } from '../../actions/banking'; // Ensure these actions exist
import CSRFToken from '../../components/CSRFToken';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // For expandable row icon
import { useTheme } from '@emotion/react';

const BankingProfiles = ({
    create_banking_profile,
    load_banking_profiles,
    update_banking_profile,
    banking_profiles = [] 
}) => {
    const theme = useTheme();
    const [expandedRowId, setExpandedRowId] = useState(null);
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [editableProfile, setEditableProfile] = useState({
        id: '',
        first_name: '',
        last_name: '',
        bank_account_location: '',
        address_1: '',
        address_2: '',
        region: '',
        city: '',
        mobile_number: '',
        country: '',
        postal_code: ''
    });
    const [formData, setFormData] = useState({

        first_name: '',
        last_name: '',
        bank_account_location: '',
        address_1: '',
        address_2: '',
        region: '',
        city: '',
        mobile_number: '',
        country: '',
        postal_code: ''
    });

    useEffect(() => {
        // Fetch banking profiles when component mounts
        load_banking_profiles();
    }, [load_banking_profiles]);

    
    const onRowClick = (profile) => {
        if (expandedRowId === profile.id) {
            setExpandedRowId(null);
            setEditableProfile({
                id: '',
                first_name: '',
                last_name: '',
                bank_account_location: '',
                address_1: '',
                address_2: '',
                region: '',
                city: '',
                mobile_number: '',
                country: '',
                postal_code: ''
            });
        } else {
            setExpandedRowId(profile.id);
            setEditableProfile({ ...profile });
        }
    };
    const handleChange = (e) => {
        setEditableProfile({
            ...editableProfile,
            [e.target.name]: e.target.value
        });
    };

    const handleFormChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const handleUpdate = () => {
        // Ensure id is included and correctly formatted
        if (editableProfile.id) {
            const { id, ...profileData } = editableProfile; // Separate id from other fields
            const updatedProfile = {
                id: Number(id), // Ensure id is a number
                ...profileData
            };
            update_banking_profile(updatedProfile).then(() => {
                // Optionally refetch profiles or handle update success
                load_banking_profiles();
            });
        }
    };

    const handleSelect = (profileId) => {
        setSelectedProfileId(profileId);
    };

    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Banking Profiles
            </Typography>

            {/* Table to display existing banking profiles */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Country</TableCell>
                            <TableCell>Region</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {banking_profiles.length > 0 ? (
                            banking_profiles.map(profile => (
                                <React.Fragment key={profile.id}>
                                    <TableRow
                                        onClick={() => onRowClick(profile)}
                                        style={{
                                            backgroundColor: profile.id === selectedProfileId ? theme.palette.secondary.light : 'transparent'
                                        }}
                                    >
                                        <TableCell>{profile.country}</TableCell>
                                        <TableCell>{profile.region}</TableCell>
                                        <TableCell>{profile.city}</TableCell>
                                        <TableCell>
                                            <IconButton>
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    {expandedRowId === profile.id && (
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Grid container spacing={2}>
                                                    {Object.keys(profile).map((key) => (
                                                        key !== 'id' && key !== 'user' && (
                                                            <Grid item xs={12} sm={6} key={key}>
                                                                <FormControl variant='outlined' fullWidth margin='normal'>
                                                                    <InputLabel htmlFor={key}>{key.replace('_', ' ').toUpperCase()}</InputLabel>
                                                                    <OutlinedInput
                                                                        id={key}
                                                                        name={key}
                                                                        value={editableProfile[key] || ''}
                                                                        onChange={handleChange}
                                                                        label={key.replace('_', ' ').toUpperCase()}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        )
                                                    ))}
                                                </Grid>
                                                <Button variant="contained" color='primary' onClick={handleUpdate} sx={{ mt: 2 }}>
                                                    Update Profile
                                                </Button>
                                                <Button variant="contained" color='secondary' onClick={() => handleSelect(profile.id)} sx={{ mt: 2, ml: 2 }}>
                                                    Select this Profile
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">No banking profiles available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Form to add a new banking profile */}
            <form onSubmit={e => {
                e.preventDefault();
                create_banking_profile(formData).then(() => {
                    // Clear form after submission
                    setFormData({
                        first_name: '',
                        last_name: '',
                        bank_account_location: '',
                        address_1: '',
                        address_2: '',
                        region: '',
                        city: '',
                        mobile_number: '',
                        country: '',
                        postal_code: ''
                    });
                    // Optionally refetch profiles if needed
                    load_banking_profiles();
                });
            }}>
                <CSRFToken />
                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                    Add New Banking Profile
                </Typography>
                <Grid container spacing={2}>
                    {/* Form fields here */}
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' fullWidth margin='normal'>
                            <InputLabel htmlFor='first_name'>First Name</InputLabel>
                            <OutlinedInput
                                id='first_name'
                                name='first_name'
                                label='First Name'
                                value={formData.first_name}
                                onChange={handleFormChange}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' fullWidth margin='normal'>
                            <InputLabel htmlFor='last_name'>Last Name</InputLabel>
                            <OutlinedInput
                                id='last_name'
                                name='last_name'
                                label='Last Name'
                                value={formData.last_name}
                                onChange={handleFormChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' fullWidth margin='normal'>
                            <InputLabel htmlFor='bank_account_location'>Bank Account Location</InputLabel>
                            <OutlinedInput
                                id='bank_account_location'
                                name='bank_account_location'
                                label='Bank Account Location'
                                value={formData.bank_account_location}
                                onChange={handleFormChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' fullWidth margin='normal'>
                            <InputLabel htmlFor='address_1'>Address Line 1</InputLabel>
                            <OutlinedInput
                                id='address_1'
                                name='address_1'
                                label='Address Line 1'
                                value={formData.address_1}
                                onChange={handleFormChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' fullWidth margin='normal'>
                            <InputLabel htmlFor='address_2'>Address Line 2</InputLabel>
                            <OutlinedInput
                                id='address_2'
                                name='address_2'
                                label='Address Line 2'
                                value={formData.address_2}
                                onChange={handleFormChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' fullWidth margin='normal'>
                            <InputLabel htmlFor='region'>Region</InputLabel>
                            <OutlinedInput
                                id='region'
                                name='region'
                                label='Region'
                                value={formData.region}
                                onChange={handleFormChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' fullWidth margin='normal'>
                            <InputLabel htmlFor='city'>City</InputLabel>
                            <OutlinedInput
                                id='city'
                                name='city'
                                label='City'
                                value={formData.city}
                                onChange={handleFormChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' fullWidth margin='normal'>
                            <InputLabel htmlFor='mobile_number'>Mobile Number</InputLabel>
                            <OutlinedInput
                                id='mobile_number'
                                name='mobile_number'
                                label='Mobile Number'
                                value={formData.mobile_number}
                                onChange={handleFormChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' fullWidth margin='normal'>
                            <InputLabel htmlFor='country'>Country</InputLabel>
                            <OutlinedInput
                                id='country'
                                name='country'
                                label='Country'
                                value={formData.country}
                                onChange={handleFormChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant='outlined' fullWidth margin='normal'>
                            <InputLabel htmlFor='postal_code'>Postal Code</InputLabel>
                            <OutlinedInput
                                id='postal_code'
                                name='postal_code'
                                label='Postal Code'
                                value={formData.postal_code}
                                onChange={handleFormChange}
                            />
                        </FormControl>
                    </Grid>
                    </Grid>
                <Button variant="contained" color='secondary' type="submit" sx={{ mt: 3 }}>
                    Add Banking Profile
                </Button>
            </form>
        </div>
    );
};

const mapStateToProps = state => ({
    banking_profiles: state.banking.profiles // Adjust according to your state structure
});

export default connect(mapStateToProps, { load_banking_profiles, create_banking_profile, update_banking_profile })(BankingProfiles);









       