import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import api from '../axiosInstance';
import Loading from './Loading';

export const Filter = ({ label, value, onChange, fetchOptionsUrl }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOptions = async () => {
            setLoading(true);
            try {
                const response = await api.get(fetchOptionsUrl);
                setOptions(response.data);
            } catch (error) {
                console.error('Error fetching options:', error);
                setError('Error fetching options. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchOptions();
    }, [fetchOptionsUrl]);

    if (loading) {
        return <div>
            <Loading/>
        </div>;
    
};

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <FormControl style={{ padding: '20px', minWidth: '180px'  }}>
            <InputLabel>{label}</InputLabel>
            <Select value={value} onChange={(e) => onChange(e.target.value)}>
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const NumericFilter = ({ label, limit1, limit2, selectedValue, onChange }) => {

    const options = [
        { value: `ALL`, label: `All`},
        { value: `less_than_${limit1}` , label: `< ${limit1}` },
        { value: `between_${limit1}_and_${limit2}` , label: `${limit1}-${limit2}` },
        { value: `more_than_${limit2}` , label: `+${limit2}` }
    ];

    return (
        <FormControl style={{ padding: '20px', minWidth: '180px'  }}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} onChange={(e) => onChange(e.target.value)}>
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};



export const NumericFilterForValuation = ({ label, limit1, limit2, selectedValue, onChange }) => {

    const options = [
        { value: `ALL`, label: `All`},
        { value: `less_than_${limit1}` , label: "Startup (<10 M)" },
        { value: `between_${limit1}_and_${limit2}` , label: "Scale-up (10M - 1B)" },
        { value: `more_than_${limit2}` , label: "Unicorn (1B+)" }
    ];

    return (
        <FormControl style={{ padding: '20px', minWidth: '180px'  }}>
            <InputLabel>{label}</InputLabel>
            <Select value={selectedValue} onChange={(e) => onChange(e.target.value)}>
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
