import Cookies from 'js-cookie';
import api from '../axiosInstance';
import {
    LOAD_BANKING_PROFILES_SUCCESS,
    LOAD_BANKING_PROFILES_FAIL,
    UPDATE_BANKING_PROFILE_SUCCESS,
    UPDATE_BANKING_PROFILE_FAIL,
    CREATE_BANKING_PROFILE_SUCCESS,
    CREATE_BANKING_PROFILE_FAIL
} from './types';

export const load_banking_profiles = () => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    try {
        const res = await api.get('/api/profile/banking/', config);  // Update the API route

        if (res.data.error) {
            dispatch({
                type: LOAD_BANKING_PROFILES_FAIL
            });
        } else {
            dispatch({
                type: LOAD_BANKING_PROFILES_SUCCESS,
                payload: res.data
            });
        }

    } catch (err) {
        dispatch({
            type: LOAD_BANKING_PROFILES_FAIL
        });
    }
};

export const update_banking_profile = (profile) => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    };
    
    const { id, ...profileData } = profile;
    const body = JSON.stringify({
        id: Number(id), // Ensure id is a number
        ...profileData
    });
    try {
        const res = await api.put('/api/profile/banking/update/', body, config);  // Update the API route

        if (res.data.profile && res.data.email) {
            dispatch({
                type: UPDATE_BANKING_PROFILE_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: UPDATE_BANKING_PROFILE_FAIL
            });
        }

    } catch (err) {
        dispatch({
            type: UPDATE_BANKING_PROFILE_FAIL
        });
    }
};

export const create_banking_profile = (formData) => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
        }
    };
    const body = JSON.stringify(formData);

    try {
        const res = await api.post('/api/profile/banking/create/', body, config);  // Update the API route

        if (res.data.profile && res.data.email) {
            dispatch({
                type: CREATE_BANKING_PROFILE_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: CREATE_BANKING_PROFILE_FAIL
            });
        }

    } catch (err) {
        dispatch({
            type: CREATE_BANKING_PROFILE_FAIL
        });
    }
};
