import React, { Fragment, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { connect } from 'react-redux';
import { checkAuthenticated } from '../actions/auth';
import { load_investor_profile } from '../actions/profile';

const Layout = ({ children, checkAuthenticated, load_investor_profile }) => {
  useEffect(() => {
    checkAuthenticated();
    load_investor_profile();
  }, [checkAuthenticated, load_investor_profile]);

  return (
    <Fragment>
      <Header />
      <Navbar />
      {/* Adjust this value to match the combined height of Header and Navbar */}
        {children}

      <Footer />
    </Fragment>
  );
};

export default connect(null, { checkAuthenticated, load_investor_profile })(Layout);
