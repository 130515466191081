import React from 'react';
import { Grid, Typography, Button, Box, Divider, useMediaQuery, List, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { Search, Description, Fingerprint, ArrowForwardIos, CheckCircle } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { LightIcon, SecondaryIcon, PrimaryIcon } from '../components/ColouredIcons';
import { Blob, BlobIconBox } from '../components/Blobs';
import images from '../images';


function HowItWorks() {
    const theme = useTheme();
    const isWideScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box>

            {/* ROW INTRO  */}
            <Blob container spacing={2} variant='primary' style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '40px'
            }}>

                <Typography variant="h3" gutterBottom>
                    How It Works
                </Typography>
                <Button variant="contained" color='secondary' component={Link} to="/investments">
                    See available opportunities
                </Button>


            </Blob>

            {/* ROW BENEFITS  */}
            <Blob container spacing={2} variant='light' flexDirection='column' padding='40px'>

                <Typography variant="h4" >
                    Benefits
                </Typography>

                <Grid container item xs={12} lg={10} style={{
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'space-between',
                    justifyContent: 'space-between'
                }}>

                    <Grid item xs={12} lg={3} padding='20px'>
                        <img src={images.buyers} alt="Buyers" width='100%' />
                        <Typography variant="h6" >
                            Buyers
                        </Typography>
                        <Typography variant="classic" >
                            <List>
                                <ListItem disableGutters style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <SecondaryIcon variant='secondary' IconComponent={CheckCircle} />
                                    <Typography>
                                        Invest in private companies, outside of open funding round and at similar level than large investment institutions
                                    </Typography>
                                </ListItem>
                                <ListItem disableGutters style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <SecondaryIcon variant='secondary' IconComponent={CheckCircle} />
                                    <Typography>
                                        Buy shares in private companies you trust and love
                                    </Typography>
                                </ListItem>
                            </List>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} lg={3} padding='20px'   >
                        <img src={images.sellers} alt="Sellers" width='100%' />
                        <Typography variant="h6">
                            Sellers
                        </Typography>

                        <Typography variant="classic" >

                            <List>
                                <ListItem disableGutters style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <SecondaryIcon variant='secondary' IconComponent={CheckCircle} />
                                    <Typography>
                                        The option to convert investments into returns
                                    </Typography>
                                </ListItem>
                                <ListItem disableGutters style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <SecondaryIcon variant='secondary' IconComponent={CheckCircle} />
                                    <Typography >
                                        Re-balance your investment portfolio
                                    </Typography>


                                </ListItem>
                                <ListItem disableGutters style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <SecondaryIcon variant='secondary' IconComponent={CheckCircle} />
                                    <Typography>
                                        Get liquidity for private investment before the IPO
                                    </Typography>


                                </ListItem>
                            </List>

                        </Typography>
                    </Grid>

                    <Grid item xs={12} lg={3} padding='20px' >
                        <img src={images.companies} alt="Companies" width='100%' />
                        <Typography variant="h6">
                            Companies
                        </Typography>

                        <Typography variant="classic">

                            <List>
                                <ListItem disableGutters style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <SecondaryIcon variant='secondary' IconComponent={CheckCircle} />
                                    <Typography >
                                        Enable investors to exit when they’re ready

                                    </Typography>
                                </ListItem>
                                <ListItem disableGutters >
                                    <SecondaryIcon variant='secondary' IconComponent={CheckCircle} />
                                    <Typography >
                                        Attract new investors into your capital

                                    </Typography>


                                </ListItem>
                                <ListItem disableGutters >
                                    <SecondaryIcon variant='secondary' IconComponent={CheckCircle} />
                                    <Typography>
                                        Benefits from a seasoned investment team to manage all the share capital changes
                                    </Typography>


                                </ListItem>
                            </List>
                        </Typography>
                    </Grid>
                </Grid>

            </Blob>

            {/* ROW BUYING  */}
            <Blob container spacing={2} variant='paper' flexDirection='column' padding='40px' >

                <Typography variant="h4" style={{ color: 'black', marginBottom: 20 }}>
                    Buying process
                </Typography>
                <Typography variant="body1">
                    Buy shares in a business without having to wait for new funding rounds
                </Typography>

                <Box padding='20px'>
                    <Box display={!isWideScreen ? 'none' : 'flex'} justifyContent="center" position="relative">
                        <Box display="flex" justifyContent="space-between" alignItems='center' width="80%" padding='20px'>
                            <BlobIconBox variant='light'>
                                <SecondaryIcon variant='secondary' IconComponent={Search} />
                            </BlobIconBox>

                            <BlobIconBox variant='secondary'>
                                <LightIcon variant='secondary' IconComponent={ArrowForwardIos} />
                            </BlobIconBox>

                            <BlobIconBox variant='light'>
                                <SecondaryIcon variant='secondary' IconComponent={Description} />
                            </BlobIconBox>

                            <BlobIconBox variant='secondary'>
                                <LightIcon variant='secondary' IconComponent={ArrowForwardIos} />
                            </BlobIconBox>

                            <BlobIconBox variant='light'>
                                <SecondaryIcon variant='secondary' IconComponent={Fingerprint} />
                            </BlobIconBox>

                            <BlobIconBox variant='secondary'>
                                <LightIcon variant='secondary' IconComponent={ArrowForwardIos} />
                            </BlobIconBox>

                            <BlobIconBox variant='light'>
                                <SecondaryIcon variant='secondary' IconComponent={Description} />
                            </BlobIconBox>

                        </Box>
                        <Box style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: '120%',
                            zIndex: 0,
                        }}
                        >
                            <Divider style={{ backgroundColor: 'lightgrey' }} />
                        </Box>
                    </Box>

                    {/* Four Columns */}
                    <Grid container spacing={2} display="flex" flexWrap="wrap" justifyContent="center" alignItems="top" paddingTop='20px' textAlign={'left'}>

                        < Grid item xs={10} lg={2}>
                            <Typography variant="h6">1. Choose share lot</Typography>
                            <Typography variant="body1" > First come, first served. 5% transaction fee
                            </Typography>
                        </Grid>
                        <Grid item xs={0} lg={1}>

                        </Grid>
                        <Grid item xs={10} lg={2}>
                            <Typography variant="h6">2. Make the payment</Typography>
                            <Typography variant="body1" > Pay instantly using a bank card or available funds in your investment account. An email will be sent to the seller to notify them of your interest in buying.
                            </Typography>
                        </Grid>
                        <Grid item xs={0} lg={1}>

                        </Grid>
                        <Grid item xs={10} lg={2}>
                            <Typography variant="h6">3. Wait For Approval</Typography>
                            <Typography variant="body1" >
                                The seller has to finalize the transfer of beneficial ownership agreement and agree to the sale.
                            </Typography>
                        </Grid>
                        <Grid item xs={0} lg={1}>

                        </Grid>
                        <Grid item xs={10} lg={2}>
                            <Typography variant="h6">4. Shares are transferred</Typography>
                            <Typography variant="body1" >
                                Once the payment has cleared, the shares will be transferred to your portfolio.
                            </Typography>
                        </Grid>

                    </Grid>
                </Box>


            </Blob>

            {/* ROW SELLING  */}
            <Blob container spacing={2} flexDirection='column' padding='40px'>

                <Typography variant="h4" style={{ color: 'black', marginBottom: 20 }}>
                    Selling process
                </Typography>
                <Typography variant="body1">
                    Investors now have the opportunity to turn their investments into returns without waiting for the business to be acquired, or floated on the stockmarket
                </Typography>

                <Box padding='20px'>
                    <Box display={!isWideScreen ? 'none' : 'flex'} justifyContent='center' position="relative">
                        <Box display="flex" justifyContent="space-between" width="80%" alignItems='center'  padding='20px'>
                            <BlobIconBox variant='light'>
                                <PrimaryIcon variant='secondary' IconComponent={Search} />
                            </BlobIconBox>
                            <BlobIconBox variant='primary'>
                                <LightIcon variant='secondary' IconComponent={ArrowForwardIos} />
                            </BlobIconBox>
                            <BlobIconBox variant='light'>
                                <PrimaryIcon variant='secondary' IconComponent={Description} />
                            </BlobIconBox>
                            <BlobIconBox variant='primary'>
                                <LightIcon variant='secondary' IconComponent={ArrowForwardIos} />
                            </BlobIconBox>
                            <BlobIconBox variant='light'>
                                <PrimaryIcon variant='secondary' IconComponent={Fingerprint} />
                            </BlobIconBox >
                            <BlobIconBox variant='primary'>
                                <LightIcon variant='secondary' IconComponent={ArrowForwardIos} />
                            </BlobIconBox>
                            <BlobIconBox variant='light'>
                                <PrimaryIcon variant='secondary' IconComponent={Description} />
                            </BlobIconBox>

                        </Box>
                        <Box
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                width: '120%',
                                zIndex: 0,
                            }}
                        >
                            <Divider style={{ backgroundColor: 'lightgrey' }} />
                        </Box>
                    </Box>

                    {/* Four Columns */}
                    <Grid container spacing={2} display="flex" flexWrap="wrap" justifyContent="center" alignItems="top" paddingTop='20px' textAlign={'left'}>

                        < Grid item xs={10} lg={2}>
                            <Typography variant="h6">1. Post your shares to be sold</Typography>
                            <Typography variant="body1" >
                                You can set your own sale price. The share lot value must be at least £/€10 and cannot exceed £/€25,000.
                            </Typography>
                        </Grid>
                        <Grid item xs={0} lg={1}>
                        </Grid>
                        < Grid item xs={10} lg={2}>
                            <Typography variant="h6">2. Eligibility check</Typography>
                            <Typography variant="body1" >
                                We'll check whether the shares are eligible to be sold and let you know. Learn more
                            </Typography>
                        </Grid>
                        <Grid item xs={0} lg={1}>
                        </Grid>

                        < Grid item xs={10} lg={2}>
                            <Typography variant="h6">3. Finalise your sale</Typography>
                            <Typography variant="body1" >
                                You will be notified when a buyer expressed an interest. You will need to finalise the agreement and agree to the transfer.
                            </Typography>
                        </Grid>
                        <Grid item xs={0} lg={1}>
                        </Grid>
                        < Grid item xs={10} lg={2}>
                            <Typography variant="h6">4. Funds transferred</Typography>
                            <Typography variant="body1" >
                                Proceeds from the transfer will be deposited straight into your investment account, less the carry and transaction fees.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>


            </Blob>

            {/* ROW TRY IT NOW  */}
            <Blob container spacing={2} variant='paper' padding='30px' style={{
                display:'flex',
                justifyContent:'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '40px'
            }}>


                        <Typography variant="h3" padding={'20px'} gutterBottom>
                            Try it now
                        </Typography>
                        <Typography variant="body1" padding={'20px'} gutterBottom>
                            Don’t miss out the best private equity deals
                        </Typography>
                        <Button variant="contained" color='secondary' component={Link} to="/investments">
                            See available opportunities
                        </Button>



            </Blob>


        </Box>
    );
}

export default HowItWorks;
