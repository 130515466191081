import {
    LOAD_INVESTOR_PROFILE_FAIL,
    LOAD_INVESTOR_PROFILE_SUCCESS,
    UPDATE_INVESTOR_PROFILE_FAIL,
    UPDATE_INVESTOR_PROFILE_SUCCESS
} from '../actions/types';

const initialState = {
    investing_anticipation:'', 
    target_investment_per_investment:'',
    interested_investment_solution:'',
    other_assets:'', 
    max_investment_horizon:'',
    risk_acceptance:'', 
    investment_experience:'', 
    liquid_worth:'',
    risk_factors:'',
    qualified_purchaser: ''
}




const profile = function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {

        case UPDATE_INVESTOR_PROFILE_SUCCESS:
        case LOAD_INVESTOR_PROFILE_SUCCESS:
            return {
                ...state,
                investing_anticipation: payload.profile.investing_anticipation ,
                target_investment_per_investment: payload.profile.target_investment_per_investment,
                interested_investment_solution: payload.profile.interested_investment_solution,
                other_assets: payload.profile.other_assets ,
                max_investment_horizon: payload.profile.max_investment_horizon,
                risk_acceptance: payload.profile.risk_acceptance ,
                investment_experience: payload.profile.investment_experience, 
                liquid_worth: payload.profile.liquid_worth,
                risk_factors: payload.profile.risk_factors,
                qualified_purchaser: payload.profile.qualified_purchaser
            }
        case LOAD_INVESTOR_PROFILE_FAIL:
            return {
                ...state,
                
                first_name:'',
                last_name:'',
                email:'',
                mobile_number:'', 
                country:'',
                postal_code:'',
                investing_anticipation:'', 
                target_investment_per_investment:'',
                interested_investment_solution:'',
                other_assets:'', 
                max_investment_horizon:'',
                risk_acceptance:'', 
                investment_experience:'', 
                liquid_worth:'',
                risk_factors:'',
                qualified_purchaser:''

            }
        case UPDATE_INVESTOR_PROFILE_FAIL:
            return {
                ...state
            }
        default:
            return state
    }
};

export default profile;