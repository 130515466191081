import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, FormControlLabel, Checkbox, LinearProgress, Card, CardActionArea, CardContent, TextField } from '@mui/material';
import { useTheme } from '@emotion/react';
import RuleBook from '../pages/RuleBook';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

const names={
    'register_verification': 'Investor Verification',
    'register_agreements': 'Agreements',
    'register_goals': 'Goals Setting',
    'register_profile': 'Completing Profile',
    'register_survey': 'Investor Survey',
}
const ScreeningForm = ({ formName, onFormComplete }) => {
    const theme = useTheme();
    const [formSteps, setFormSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [isAccepted, setIsAccepted] = useState({});
    
    useEffect(() => {


    const filterStepsByCondition = (steps, data) => {
        return steps.filter(step => {
            if (!step.condition) {
                return true;
            }
    
            const { all, any } = step.condition;
            if (all) {
                return all.every(condition => checkCondition(condition, data));
            }
            if (any) {
                return any.some(condition => checkCondition(condition, data));
            }
            return true;
        });
    };
        const loadFormData = async () => {
            const response = await import(`../content/${formName}.json`);
            const filteredSteps = filterStepsByCondition(response.steps, formData);
            setFormSteps(filteredSteps);
        };
        loadFormData();
    }, [formName, formData]);
    
    const handleNext = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handleChoiceChange = (questionId, choiceId) => {
        setFormData((prevData) => ({
            ...prevData,
            [questionId]: choiceId,
        }));
    };

    const handleSelectChange = (questionId, choiceId) => {
        setFormData((prevData) => {
            const selectedChoices = prevData[questionId] || [];
            if (selectedChoices.includes(choiceId)) {
                return {
                    ...prevData,
                    [questionId]: selectedChoices.filter((id) => id !== choiceId),
                };
            } else {
                return {
                    ...prevData,
                    [questionId]: [...selectedChoices, choiceId],
                };
            }
        });
    };

    
    const checkCondition = (condition, data) => {
        const { questionId, answer } = condition;
        if (typeof answer === 'object' && 'not' in answer) {
            return data[questionId] !== answer.not;
        }
        return data[questionId] === answer;
    };

    const handleInputChange = (questionId, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [questionId]: value,
        }));
    };
    
    const handleCheckboxChange = (stepId, checked) => {
        setIsAccepted((prevAccepted) => ({
            ...prevAccepted,
            [stepId]: checked,
        }));
    };

    const handleDateChange = (questionId, date) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [questionId]: date,
        }));
        };

    const renderStep = (step) => {
        switch (step.question?.type || step.type) {
            case 'choice':
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                        <Typography variant="h6" gutterBottom>
                            {step.question.question}
                        </Typography>
                        {step.question.choices.map((choice) => (
                            <Card
                                key={choice.id}
                                onClick={() => handleChoiceChange(step.question.id, choice.id)}
                                sx={{
                                    backgroundColor: formData[step.question.id] === choice.id ? theme.palette.secondary.light : 'white',
                                    width: '50%'
                                }}
                            >
                                <CardActionArea>
                                    <CardContent>
                                        <Typography variant='body1'>
                                            {choice.name}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        ))}
                    </Box>
                );

            case 'input':
                    return (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom>
                                {step.question.question}
                            </Typography>
                            <TextField
                                key={step.question.id}
                                label="Your Answer"
                                variant="outlined"
                                fullWidth
                                value={formData[step.question.id] || ''}
                                onChange={(e) => handleInputChange(step.question.id, e.target.value)}
                                sx={{ width: '50%' }}
                            />
                        </Box>
                    );
            case 'date':
                return (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                                <Typography variant="h6" gutterBottom>
                                    {step.question.question}
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Select Date"
                                        value={formData[step.question.id] || null}
                                        onChange={(newValue) => handleDateChange(step.question.id, newValue)}
                                        renderInput={(params) => <TextField {...params} sx={{ width: '50%' }} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                        );
            case 'message':
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                        <Typography variant="body1" gutterBottom>
                            <span dangerouslySetInnerHTML={{ __html: step.message }} />
                        </Typography>
                    </Box>
                );
                case 'check':
                    return (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom>
                                {step.question.question}
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                                {step.question.choices.map((choice) => (
                                    <Card
                                        key={choice.id}
                                        onClick={() => handleSelectChange(step.question.id, choice.id)}
                                        sx={{
                                            backgroundColor: (formData[step.question.id] || []).includes(choice.id) ? theme.palette.secondary.light : 'white',
                                            width: '20%'
                                        }}
                                    >
                                        <CardActionArea>
                                            <CardContent>
                                                <Typography variant='body1'>
                                                    {choice.name}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                ))}
                            </Box>
                        </Box>
                    );
            case 'accept':
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center',  }}>
                        <Typography variant="h6" gutterBottom>
                            {step.message}
                        </Typography>
                    
                    <Box sx={{
                            height: '50vh', 
                            overflowY: 'auto', 
                     
                            }}>

                    <RuleBook title={step.path}/>
                    </Box>
                        
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAccepted[step.id] || false}
                                    onChange={(e) => handleCheckboxChange(step.id, e.target.checked)}
                                />
                            }
                            label="I have read and accept"
                        />
                    </Box>
                );

            default:
                return null;
        }
    };

    const handleSubmit = () => {
        console.log('Submitting data', formData);
        if (onFormComplete) {
            onFormComplete();
        }
    };

    const isLastStep = currentStep === formSteps.length - 1;

    return (
        <Box sx={{
          
        }}>
            
            <LinearProgress variant="determinate" color='secondary' value={(currentStep / formSteps.length) * 100} />
            <Typography padding='20px' variant='h4' textAlign={'center'}>{names[formName]}</Typography>
            <Box padding='20px'>
                {formSteps.length > 0 && renderStep(formSteps[currentStep])}
            </Box>
            <Box mt={2} display='flex' justifyContent={'center'}>
                {currentStep < formSteps.length - 1 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={formSteps[currentStep]?.type === 'accept' && !isAccepted[formSteps[currentStep].id]}
                    >
                        Next
                    </Button>
                )}
                {isLastStep && (
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default ScreeningForm;
