import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import api from '../axiosInstance';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


const LogoCarousel = () => {
    const [displayLogos, setDisplayLogos] = useState([]);

    useEffect(() => {

        const fetchLogos = async () => {
            try {
                const response = await api.get('/api/products/logos/');
                const logosData = response.data;

                const minSlides = 2000;
                let logosToShow = logosData;

                while (logosToShow.length < minSlides) {
                    logosToShow = logosToShow.concat(logosData);
                }

                setDisplayLogos(logosToShow);

            } catch (error) {
                console.error('Error fetching logos:', error)
            }
        };
        fetchLogos();
    }, []);

    const settings = {
        spaceBetween: 30,
        
        breakpoints:{
            480: {
                slidesPerView: 3,
                slidesPerGroup: 1,
            },
            768: {
                slidesPerView: 3,
                slidesPerGroup: 1,
            },
            1024: {
                slidesPerView: 10,
                slidesPerGroup: 1,
            }
        },
        loop: true,
        autoplay: {
            delay: 1000,
            disableOnInteraction: false,
        },
        className:"mySwiper",
        style:{
            width:'100%',
            padding: '20px',
            margin: '0 auto'
        }
    }

    return (
        <div className='logo-carousel-container' position='relative'>
        <Swiper{...settings} 
                modules={[Autoplay, Pagination, Navigation]}
                autoplay={settings.autoplay}
           >
            {displayLogos.map(
                (company, index) => (
                    <SwiperSlide key={index} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                        <a href={`/company/${company.id}`}>
                        <img src={company.logo} alt={company.name} style={{  filter: 'grayscale(100%)', width: '50px', height: '50px', maxWidth: '100%'}} />
                        </a>

                    </SwiperSlide>
                )
            )
            }
        </Swiper>
    </div >
    );
};

export default LogoCarousel;