import React from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import images from '../images';
import { logout } from '../actions/auth';

const Navbar = ({ isAuthenticated, logout }) => {
  
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);



  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };



  const menuItems = [
    { text: 'Investments', link: '/investments', variant:'text'},
    { text: 'How It Works', link: '/howitworks', variant:'text'},
    ...(isAuthenticated ? [
      { text: 'Portfolio', link: '/portfolio', variant:'text'},
      { text: 'My Account', link: '/profile' , variant:'text'},
      { text: 'Logout', onClick: logout , variant:'text'}
    ] : [
      { text: 'Login', link: '/login', variant:'login'},
      { text: 'Register', link: '/register', variant:'register'}
    ])
  ];


  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem key={index} style={{ color:theme.palette.text.light }} component={Link} to={item.link} onClick={item.onClick}>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" elevation={0} sx={{ top:'64px', zIndex: theme.zIndex.drawer + 1 }}>
            <Toolbar>
        <Box component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', padding: '30px' }}>
          <a href='/'>
            <img src={images.logo} alt="Logo" style={{ height: '40px' }} />
          </a>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {menuItems.map((item, index) => (
            <Button key={index} variant={item.variant} component={Link} to={item.link} sx={{
              color: 'white',
              textTransform: 'none',
              ...(item.variant === 'login' && {
                backgroundColor: 'white',
                color: theme.palette.primary.main,
                borderWidth: 1,
                '&:hover': {
                  borderColor: theme.palette.primary.light,
                  color: 'white'
                }
              }),
              ...(item.variant === 'register' && {
                backgroundColor: 'white',
                color: theme.palette.secondary.main,
                borderWidth: 1,
                '&:hover': {
                  borderColor: theme.palette.secondary.light,
                  color: 'white'
                }
              })
            }}
            onClick={item.onClick ? item.onClick : null}>
              {item.text}
            </Button>
          ))}
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer(true)}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <MenuIcon color='white' />
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)} PaperProps={{
          sx: {
            backgroundColor: theme.palette.primary.main,
            color: 'white'
          }
        }}>

          <img src={images.logo} alt="Logo" style={{ height: '40px', margin: '20px' }} />
          {list()}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = state => ({

  isAuthenticated:state.auth.isAuthenticated
})

export default connect(mapStateToProps, { logout })(Navbar);