// src/components/OrderFormHOC.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Grid, Alert, Tab, Paper, Typography } from '@mui/material';
import ReservationDetails from '../components/order/ReservationDetails';
import AcceptTerms from '../components/order/AcceptTerms';
import CheckoutForm from '../components/order/CheckoutForm';
import ReviewOrder from '../components/order/ReviewOrder';
import api from '../axiosInstance';
import { styled } from '@mui/material/styles';
import Cookies from 'js-cookie';

import BankingProfiles from '../components/profiles/BankingProfiles';

const BlobContent = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '80%',
  margin: '0 auto',
  paddingTop: '40px',
  paddingBottom: '40px',
})

const OrderForm = (props) => {

  const [tabValue, setTabValue] = useState('1');
  const [formData, setFormData] = useState({
    reservation_amount: 0,
    share_price: 0,
    equivalent_number_of_shares: 0,
    final_reservation_amount: 0,
    term_sheet_acceptance1: false,
    term_sheet_acceptance2: false,
    term_sheet_acceptance3: false,
  });
  const [error, setError] = useState(''); // or you can remove both if neither is used
  const [orderId, setOrderId]= useState(null);
  const totalTabs = 5;
  const { dealId } = useParams();


  const handleChange = (event) => {
    const { name, value } = event.target;
    let newFormData = { ...formData, [name]: value };

    // If reservation_amount changes, recalculate other fields
    if (name === 'equivalent_number_of_shares') {
      newFormData = {
        ...newFormData,
        reservation_amount: Math.round(formData.share_price * value),
        final_reservation_amount: Math.round(formData.share_price * value) * 0.95,
      };
    }

    setFormData(newFormData);
  };


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleNext = () => {
    const nextTab = parseInt(tabValue) + 1;
    setTabValue(nextTab.toString());
  };

  const handleBack = () => {
    const prevTab = parseInt(tabValue) - 1;
    setTabValue(prevTab.toString());
  };

  const createOrder = async () => {

    const orderData = {
      deal: dealId,
      reservation_amount: formData.reservation_amount,
      equivalent_number_of_shares: formData.equivalent_number_of_shares,
      final_reservation_amount: formData.final_reservation_amount,
      status: 0
    };

    const orderResponse = await api.post('/api/orders/place_order/', orderData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      });

    if (orderResponse.status === 201) {
      const nextTab = parseInt(tabValue) + 1;
      setTabValue(nextTab.toString());
      console.log(orderResponse.data.id)
      setOrderId(orderResponse.data.id)

    } else {
      setError('Something is wrong, cannot proceed');
    }
  }


  const renderButtons = () => {
    if (tabValue === "1") {
      return (
        <Button variant="contained" color="primary" onClick={handleNext}>
          Next
        </Button>
      );
    } else if (tabValue === (totalTabs - 1).toString()) {
      return (
        <>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="secondary" onClick={createOrder}>
            Proceed to Payment
          </Button>
        </>
      );
    } else if (tabValue === totalTabs.toString()) {
      return (
        <>

        </>
      );
    }
    else {
      return (
        <>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        </>
      );
    }
  };


  if (error) return <div>{error}</div>;

  return (

    <BlobContent>
      <Box component={Paper} style={{ width: '100%', padding: '60px', topMargin: '60px' }}>

        <form>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="order form tabs">
                <Tab label="Reservation Details" value="1" />
                <Tab label="Personal Information" value="2" />
                <Tab label="Accept Terms" value="3" />
                <Tab label="Review" value="4" />
                <Tab label="Payment" value="5" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <ReservationDetails formData={formData} setFormData={setFormData} handleChange={handleChange} />
            </TabPanel>


            <TabPanel value="2">
              <Alert severity='info'>
                Please select a relevant payment profile for this transaction or add a new one. Note: P.O. Boxes are not accepted.
              </Alert>
              <BankingProfiles formData={formData} handleChange={handleChange} />
            </TabPanel>

            <TabPanel value="3">
              <AcceptTerms formData={formData} handleChange={handleChange} />
            </TabPanel>

            <TabPanel value="4">
              <ReviewOrder formData={formData} />
            </TabPanel>

            <TabPanel value="5">
              <CheckoutForm
                formData={formData}
                dealId={dealId}
                orderId={orderId}
              />
            </TabPanel>

          </TabContext>
        </form>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: 2,
            justifyContent: 'center',  // Horizontally centers the buttons
            alignItems: 'center',  // Vertically centers the buttons
          }}
        >
          {renderButtons()}
         <Typography>
         {error}
         </Typography>
        </Grid>

      </Box>
    </BlobContent>
  );
};

export default OrderForm;
