import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Header = () => {

    const theme = useTheme();

    return (
        <AppBar position='sticky' sx={{ height:'64px', backgroundColor: theme.palette.primary.main, zIndex: 1300, boxShadow:theme.shadows[1]}}>
            <Toolbar style={{ height: '10px', justifyContent:'center' }}>
                <Typography variant='body2' style={{ color: 'white' }}>
                ✨ Invest in your favourite private companies! <a href='/login' style = {{textDecoration:'none', color: 'inherit'}}><u>Open an Avance account now.</u>  </a>Capital at risk. This is not investment advice.
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default Header;