import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, ListItem, ListItemText, Paper } from '@mui/material';

const NewsHeadlines = ({ companyName }) => {
  const [headlines, setHeadlines] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHeadlines = async () => {
      try {
        const response = await axios.get(`https://newsapi.org/v2/everything`, {
          params: {
            q: companyName,
            language: 'en',
            pageSize: 4, // Limit to 4 news articles
            apiKey: '456036fe7fac49529a68b2a6ba5ab714', // Use the environment variable
          },
        });
        setHeadlines(response.data.articles);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchHeadlines();
  }, [companyName]);

  return (
    <Paper style={{ padding: '16px' }}>
      {error && <p>Error: {error}</p>}
      <List>
        {headlines.map((article, index) => (
          <ListItem key={index} button component="a" href={article.url} target="_blank" rel="noopener noreferrer">
            <ListItemText primary={article.title} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default NewsHeadlines;
